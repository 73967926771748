import queryString, { ParsedQuery } from 'query-string';
import FormContext from 'src/machines/context';
import RedirectParams from 'src/constants/redirectParams';
import {
  GetAgentInfo,
  Address,
  UTM,
  ParsedQueryParams,
  AddressComponent,
  HomeType,
  PropertyUse,
} from 'src/common/types';
import { searchAgentById, searchByTeamId } from 'src/utils/fetchActions';
import Router from 'next/router';
import { Quote } from 'src/common/apiSchemas';

export const createMarkup = (text: string) => ({ __html: text });

export const emailPattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const buildInputMask = (mask: string) => {
  let formattedMask: string | null = '';
  switch (mask) {
    case 'phone':
      formattedMask = '(000) 000-0000';
      break;
    case 'zipcode':
      formattedMask = '00000';
      break;
    case 'date':
      formattedMask = '00/00/0000';
      break;
    case 'currency':
      formattedMask = 'currency';
      break;
    default:
      formattedMask = null;
  }
  return formattedMask;
};

export const cleanObject = (obj: any) => {
  const newObj = { ...obj };
  const propNames = Object.getOwnPropertyNames(newObj);
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i];
    const propValue = newObj[propName];

    if (
      propValue === null ||
      propValue === undefined ||
      propValue === '' ||
      Number.isNaN(propValue)
    ) {
      delete newObj[propName];
    }
  }
  return newObj;
};

export const getState = (address: Address) => {
  return address.state;
};

export const lowercaseObjectKeys = (obj: any) => {
  const copy = { ...obj };
  Object.keys(copy).forEach(key => {
    const newKey = key.toLowerCase();
    copy[newKey] = copy[key];
    if (key !== newKey) {
      delete copy[key];
    }
  });
  return copy;
};

export const findCommonElements = (baseArray: any[], comparedArray: any[]) => {
  return baseArray.some(item => comparedArray.includes(item.toLowerCase()));
};

export const checkIfThereAreParams = () => {
  if (typeof window !== 'undefined') {
    // this is necessary because url can be something normal like kellermortgage.com/?KWUID=12121
    // but also kellermortgage.com/#/?KWUID=12121 so we need to handle both scenarios (with and withput hash)
    const query = Router.asPath.split('?')[1];
    if (query) {
      const parsed = queryString.parse(query);
      const lowerCaseParams = lowercaseObjectKeys(parsed);
      const paramsMatches = findCommonElements(
        Object.keys(lowerCaseParams),
        RedirectParams
      );
      return [paramsMatches, lowerCaseParams] as [boolean, ParsedQueryParams];
    }
  }
  return [false, undefined] as [boolean, undefined];
};

const getUtmFromParsed = (parsed: ParsedQuery<any>, defaultUtm: UTM) => {
  return {
    source: parsed.utm_source || defaultUtm.source,
    medium: parsed.utm_medium || defaultUtm.medium,
    campaign: parsed.utm_campaign || defaultUtm.campaign,
    term: parsed.utm_term || window.location?.href || defaultUtm.term,
    content: parsed.utm_content || defaultUtm.content,
  };
};

const stringToBool = (string: string) => {
  switch (string) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return '';
  }
};

export const getQueryParamsData = async (
  authToken: string,
  params: ParsedQueryParams
) => {
  const currentOrigin = 'agentGenerated';
  const currentIsBuyerAgent = stringToBool(params.is_buyer_agent);
  const currentIsSellerAgent = stringToBool(params.is_seller_agent);
  const currentLoanMgrFromPreApp = params.loan_mgr_from_preapp || '';
  let currentCampaignID = params.campaign_id || 45;
  const currentUrlHasCampaignId = !!params.campaign_id;
  const currentUTM = getUtmFromParsed(params, FormContext.utm);
  const parsedLoanManagerId = parseInt(params.loan_manager_id, 10)
    ? parseInt(params?.loan_manager_id, 10)
    : '';
  const currentAgent = params.kwuid
    ? await searchAgentById(params.kwuid, authToken)
    : 'invalid_kwuid';
  const currentTeam = params.team_id
    ? await searchByTeamId(params.team_id)
    : FormContext.team;
  let currentMarketCenter = FormContext.marketCenter;
  let currentAgentObject = FormContext.agent;
  let currentGetAgentInfo = FormContext.answers.getAgentInfo;
  const currentPartnerId = parseInt(params.partnerid, 10) || 102100; // partner Id from Keller Mortgage

  // build agent object
  if (currentAgent !== 'invalid_kwuid' && currentAgent.first_name) {
    currentAgentObject = {
      ...FormContext.agent,
      firstName: currentAgent.first_name,
      lastName: currentAgent.last_name,
      phone: currentAgent.mobile_phone,
      KWUID: currentAgent.kw_uid.toString(),
      photo: currentAgent.photo,
      email: currentAgent.email,
      loanManagerId: parsedLoanManagerId,
    };
    currentMarketCenter = {
      id: currentAgent.org_key,
      name: currentAgent.org_name,
    };
    currentGetAgentInfo = GetAgentInfo.HAS_AGENT;
    if (!parseInt(params?.campaign_id, 10)) {
      currentCampaignID = 45;
    }
  } else {
    currentAgentObject = {
      ...FormContext.agent,
      firstName: currentTeam.teamName,
      KWUID: 'invalid_kwuid',
      loanManagerId: parsedLoanManagerId,
    };
    if (currentTeam.teamID && currentTeam.teamName) {
      currentGetAgentInfo = GetAgentInfo.HAS_AGENT;
    }
    if (!parseInt(params?.campaign_id, 10)) {
      currentCampaignID = 46;
    }
  }

  return {
    origin: currentOrigin,
    isBuyerAgent: currentIsBuyerAgent,
    isSellerAgent: currentIsSellerAgent,
    loanFromPreApp: currentLoanMgrFromPreApp,
    utm: currentUTM,
    campaignID: currentCampaignID,
    urlHasCampaignId: currentUrlHasCampaignId,
    agent: currentAgentObject,
    team: currentTeam,
    marketCenter: currentMarketCenter,
    getAgentInfo: currentGetAgentInfo,
    partnerId: currentPartnerId,
    aba: params.aba || 'on',
    buyOrRefinance: params.buyorrefinance,
  };
};

export const debounce = (func: (..._: any[]) => void, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | null;
  return (...args: []) => {
    const context: void = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const findAddrComponent = (
  addrComponent: string,
  addrArr: AddressComponent[],
  nameLength: 'short_name' | 'long_name'
) => {
  if (addrArr) {
    const component = addrArr.find(addrComponentItem => {
      return addrComponentItem.types.includes(addrComponent);
    });
    return component?.[nameLength] || '';
  }
  return '';
};

export const sortQuotesByLowerPrice = (a: Quote, b: Quote) => {
  if (parseFloat(a.annual_premium_quote) < parseFloat(b.annual_premium_quote)) {
    return -1;
  }
  if (parseFloat(a.annual_premium_quote) > parseFloat(b.annual_premium_quote)) {
    return 1;
  }
  return 0;
};

export const mapHomeType = (homeType: HomeType | '') => {
  if (homeType) {
    switch (homeType) {
      case HomeType.SINGLE_FAMILY:
        return 'SINGLE_FAMILY';
      case HomeType.MULTI_FAMILY:
        return 'MULTI_FAMILY';
      case HomeType.CONDO:
        return 'CONDO';
      case HomeType.TOWNHOME:
        return 'TOWNHOME';
      case HomeType.OTHER:
        return 'OTHER';
      default:
        return '';
    }
  }
  return '';
};

export const mapPropUse = (propertyUse: PropertyUse | '') => {
  if (propertyUse) {
    switch (propertyUse) {
      case PropertyUse.PRIMARY:
        return 'PRIMARY';
      case PropertyUse.SECONDARY:
        return 'SECONDARY';
      case PropertyUse.SEASONAL:
        return 'SEASONAL';
      case PropertyUse.RENTAL:
        return 'RENTAL_PROPERTY';
      default:
        return '';
    }
  }
  return '';
};
