export const formatPhoneNumber = (phone?: string) => {
  if (phone) {
    // remove characters
    const phoneWithoutChar = phone.replace(/[^\d]/g, '');
    // add format
    const formattedPhone = phoneWithoutChar.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1-$2-$3'
    );
    return formattedPhone;
  }
  return '';
};

export const convertToISOString = (date?: string) => {
  if (date !== undefined) {
    const newDate = new Date(date);
    return newDate.toISOString();
  }
  return '';
};

export const formatAddress = (address?: string) => {
  if (address) {
    const lastCommaIndex = address.lastIndexOf(',');
    const formattedAddress = `${address.slice(0, lastCommaIndex)}`;
    return formattedAddress;
  }
  return '';
};

export const formatCarrierName = (name: string) => {
  const nameArray = name
    .replace(/(\(([^]+)\)|\s)/g, ' ')
    .toLowerCase()
    .trim()
    .split(' ');
  const nameArrayShorted =
    nameArray.length >= 2 ? nameArray.splice(0, 2) : nameArray;
  if (name === 'Acuity Insurance') {
    return nameArrayShorted[0];
  }
  return nameArrayShorted.join('');
};
