import Head from 'next/head';
import { useFormMachine } from 'src/context/FormMachineProvider';
import { SEOLang } from 'src/data/lang/SEO';

interface Seo {
  title?: string;
  description?: string;
}
export default function SEO({ title, description }: Seo) {
  const [
    {
      context: { jvInfo = { PartnerOrganizationName: '' } },
    },
  ] = useFormMachine();

  const seo: Seo = {
    title: title || jvInfo.PartnerOrganizationName || SEOLang.title,
    description: description || SEOLang.description,
  };
  return (
    <Head>
      {/* We don't want to show title if there's an incongruity between deploy variation and seo title (it can't be mypreapp with keller mortgage title) */}
      {!(
        process.env.NEXT_PUBLIC_DEPLOY_VARIATION === 'mypreapp' &&
        seo.title === 'Your Mortgage Application'
      ) && <title>{`${seo.title}`}</title>}
      <meta name="description" content={seo.description} />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
    </Head>
  );
}
