import React, { useEffect } from 'react';

import Modal from 'components/commons/Modal/Modal';
import { useFormMachine } from 'src/context/FormMachineProvider';
import { useActor } from '@xstate/react';
import { ActorRefFrom } from 'xstate';

import sendGaEvent from 'src/utils/gtag';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import saveProgressMachine from 'src/machines/saveProgressMachine';
import styles from './save-confirmation-modal.module.scss';

const SaveConfirmationModal = () => {
  const [current, send] = useFormMachine();
  const { saveProgress } = current.context;
  const [, sendSaveProgress] = useActor<
    ActorRefFrom<typeof saveProgressMachine>
  >(saveProgress);
  const isKellerMortgage = current.context.jvInfo?.PartnerID === 102100;

  useEffect(() => {
    sendGaEvent('saveConfirmationModal', 'mortgageRate');
  }, []);

  const handleClose = () => {
    sendGaEvent('saveConfirmationModalClose', 'mortgageRate');
    sendSaveProgress('CLOSE');
  };

  const handleReturnToHome = async () => {
    sendGaEvent('saveConfirmationModalClose', 'mortgageRate');
    await sendSaveProgress('CLOSE');
    send('NAVIGATE_TO_HOME');
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      className={styles.saveConfirmationModalWrapper}
    >
      <div className={styles.saveConfirmationModalContainer}>
        <p className={styles.text}>Thanks!</p>
        <p className={styles.subText}>
          A link has been sent to your email. See you soon!
        </p>
        {isKellerMortgage && (
          <SecondaryButton handleOnClick={() => handleReturnToHome()}>
            Return to KM home
          </SecondaryButton>
        )}
      </div>
    </Modal>
  );
};

export default SaveConfirmationModal;
