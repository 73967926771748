import Modal from 'components/commons/Modal/Modal';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import React, { useEffect } from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';
import { formatPhoneNumber } from 'src/utils/formats';
import sendGaEvent from 'src/utils/gtag';

import styles from './flow-completed.module.scss';

const FlowCompleted = () => {
  const [state, send] = useFormMachine();
  const isKellerMortgage = state.context.jvInfo?.PartnerID === 102100;
  const { ContactPhone } = state.context.jvInfo;

  useEffect(() => {
    sendGaEvent('flowCompletedModal', 'mortgageRate');
  }, []);

  return (
    <Modal isOpen className={styles.container}>
      <h4 className={styles.title}>
        {state.context.redirectUrl
          ? "We've received your pre-application"
          : "We've encountered an error"}
      </h4>
      <div className={styles.textContent}>
        {state.context.redirectUrl ? (
          <p>
            A loan officer will reach out to guide you through the rest of the
            application process.
          </p>
        ) : (
          <div>
            <p>
              {isKellerMortgage
                ? 'Our support staff will reach out to you to help you continue the application process or you can contact them directly:'
                : `We're sorry, but we've encountered an error. Please try again later, or call us ${
                    ContactPhone ? formatPhoneNumber(ContactPhone) : ''
                  } to continue with your application.`}
            </p>
            {isKellerMortgage && (
              <ul>
                <li>
                  Support Email:
                  <a
                    className={styles.call}
                    href="mailto:support@kellermortgage.com"
                  >
                    {' '}
                    support@kellermortgage.com
                  </a>
                </li>
                <li>
                  Support Phone #:
                  <a className={styles.call} href="tel:+1  888-766-2678">
                    {' '}
                    888-766-2678
                  </a>
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
      {isKellerMortgage ? (
        <SecondaryButton
          handleOnClick={() => {
            sendGaEvent('flowCompletedModalClose', 'mortgageRate');
            send('RETURN_HOME');
          }}
        >
          Return to KM home
        </SecondaryButton>
      ) : null}
    </Modal>
  );
};

export default FlowCompleted;
