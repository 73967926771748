import { FormContext, ParsedQueryParams } from 'src/common/types';
import { checkIfThereAreParams, getQueryParamsData } from 'src/utils';
import {
  getJvPartnerDetails,
  getOrUpdateSessionId,
} from 'src/utils/fetchActions';
import { assign, DoneInvokeEvent } from 'xstate';
import jvs from 'src/data/jv/jv.json';

export const initializingNode = {
  initial: 'activateModal',
  on: {
    // if user clicks start flow button before initials fetching is done
    START_FLOW: { actions: { type: 'changeModal', modal: 'fullPageLoader' } },
  },
  states: {
    activateModal: {
      always: [
        {
          target: 'initSession',
          cond: 'queryParamsAvailable',
          actions: { type: 'changeModal', modal: 'loading' },
        },
        {
          target: 'initSession',
          actions: { type: 'changeModal', modal: '' },
        },
      ],
    },
    initSession: {
      invoke: {
        id: 'initSessionId',
        src: (context: FormContext) => getOrUpdateSessionId(context, null),
        onDone: {
          target: 'getQueryParamsData',
          actions: 'setSessionData',
        },
        onError: {
          target: '#invalidPartner',
          actions: { type: 'changeModal', modal: 'error' },
        },
      },
    },
    getQueryParamsData: {
      on: {
        NO_QUERY_PARAMS_DATA: {
          target: '#invalidPartner',
          actions: { type: 'changeModal', modal: '' },
        },
        GET_JV_PARTNER_DETAILS: { target: 'getJvPartnerDetail' },
        START_FLOW: { target: '#steps.hist' },
        INVALID_PARTNER: { target: '#invalidPartner' },
      },
      invoke: {
        id: 'getQueryParamsData',
        src: (context: FormContext) => (cb: any) => {
          const [saveParams, parsed] = checkIfThereAreParams();
          const isJv = process.env.NEXT_PUBLIC_DEPLOY_VARIATION === 'mypreapp';

          if (parsed?.partnerid) {
            const isJVAndKellerPartner = isJv && parsed.partnerid === '102100';
            if (isJVAndKellerPartner) {
              cb('INVALID_PARTNER');
            }
          }
          if (saveParams) {
            return getQueryParamsData(
              context.flowInfo.authToken,
              parsed as ParsedQueryParams
            );
          }
          cb('NO_QUERY_PARAMS_DATA');

          return undefined;
        },
        onDone: {
          target: 'getJvPartnerDetail',
          actions: { type: 'setQueryParamsData' },
        },
        onError: { target: 'initSessionFinal' },
      },
    },
    getJvPartnerDetail: {
      invoke: {
        id: 'getJvPartnerDetail',
        src: (context: FormContext) => {
          return getJvPartnerDetails(context.partnerId);
        },
        onDone: [
          {
            cond: 'queryParamsBuyFlow',
            actions: { type: 'updateJvInfo' },
            target: '#innerSteps.homeType',
          },
          {
            cond: 'queryParamsRefiFlow',
            actions: { type: 'updateJvInfo' },
            target: '#innerSteps.whatIsYourCurrentAddress',
          },
          {
            actions: { type: 'updateJvInfo' },
            target: '#innerSteps.buyOrRefinance',
          },
        ],
        onError: { target: 'initSessionFinal' },
      },
    },
    initSessionFinal: {
      type: 'final' as 'final',
    },
  },
  onDone: [{ target: '#innerSteps.buyOrRefinance' }],
};

export const initializingActions = {
  setSessionData: assign((context: FormContext, event: any) => {
    return {
      ...context,
      flowInfo: {
        ...context.flowInfo,
        authToken: event.data.authToken,
        sessionID: event.data.sessionID,
      },
      utm: {
        ...context.utm,
        term: typeof window !== 'undefined' ? window.location.href : '',
      },
    };
  }),
  setQueryParamsData: assign(
    (context: FormContext, event: DoneInvokeEvent<any>) => {
      // if we have a buy or refi query param, we want to handle the same way that the borrowers dropdown selection
      const origin = event.data.buyOrRefinance
        ? `${event.data.buyOrRefinance}Dropdown`
        : event.data.origin;
      const hasAgentOrTeam = event.data.getAgentInfo;
      return {
        ...context,
        answers: {
          ...context.answers,
          getAgentInfo: event.data.getAgentInfo,
          buyOrRefinance: hasAgentOrTeam ? event.data.buyOrRefinance : '',
        },
        flowInfo: {
          ...context.flowInfo,
          origin: hasAgentOrTeam ? origin : event.data.origin,
          campaignID: event.data.campaignID,
          urlHasCampaignId: event.data.urlHasCampaignId,
          isBuyerAgent: event.data.isBuyerAgent,
          isSellerAgent: event.data.isSellerAgent,
          loanFromPreApp: event.data.loanFromPreApp,
          aba: event.data.aba,
        },
        agent: event.data.agent,
        team: event.data.team,
        marketCenter: event.data.marketCenter,
        utm: event.data.utm,
        partnerId: event.data.partnerId,
      };
    }
  ),
  updateJvInfo: assign((context: FormContext, event: DoneInvokeEvent<any>) => {
    const preloadedJv =
      jvs.find(jv => jv.PartnerID === context.partnerId) || {};

    if (event.data.length) {
      const jvInfoApi = event.data[0];

      return {
        ...context,
        jvInfo: {
          ...context.jvInfo,
          ...preloadedJv,
          ...jvInfoApi,
          // Disclaimer: context.jvInfo.Disclaimer,
        },
      };
    }
    if (preloadedJv) {
      return { ...context, jvInfo: { ...context.jvInfo, ...preloadedJv } };
    }

    return { ...context };
  }),
};
export const initializingGuards = {
  queryParamsAvailable: () => {
    const [available] = checkIfThereAreParams();
    return available;
  },
  queryParamsBuyFlow: (context: FormContext) =>
    context.answers.buyOrRefinance === 'buy',
  queryParamsRefiFlow: (context: FormContext) =>
    context.answers.buyOrRefinance === 'refinance',
};
