const gaActions = {
  home: {
    page: 'Home - Load',
    clickedApplyNowHero: 'Home - Click Apply Now Button - Hero Section',
    clickedApplyNowTotal:
      'Home - Click Apply Now Button - Total Customers Section',
    clickedApplyNowKeller:
      'Home - Click Apply Now Button - Keller Williams Advantage Section',
    clickedApplyNowContact:
      'Home - Click Apply Now Button - Contact Us Section',
    clickedFaq: 'Home - Click FAQ Link',
    clickedContactUs: 'Home - Click Contact Us Link',
  },
  faq: {
    page: 'FAQ - Load',
    clickedPhoneNumber: 'FAQ - Click on 888-766-2678 button',
    submitForm: 'FAQ - Submit contact form',
    closeContactModal: 'FAQ - Close contact us modal',
    aboutKellerMortgageQuestion1Open:
      'FAQ - Where can I find Keller Mortgage Question Open',
    aboutKellerMortgageQuestion1Close:
      'FAQ - Where can I find Keller Mortgage Question Close',
    aboutKellerMortgageQuestion2Open:
      'FAQ - How much experience do Keller Mortgage loan officers have Question Open',
    aboutKellerMortgageQuestion2Close:
      'FAQ - How much experience do Keller Mortgage loan officers have Question Close',
    aboutKellerMortgageQuestion3Open:
      'FAQ - Tell me all about zero plus Question Open',
    aboutKellerMortgageQuestion3Close:
      'FAQ - Tell me all about zero plus Question Close',
    aboutKellerMortgageQuestion4Open:
      'FAQ - This sounds great. How can I access the zero plus program Question Open',
    aboutKellerMortgageQuestion4Close:
      'FAQ - This sounds great. How can I access the zero plus program Question Close',
    aboutKellerMortgageQuestion5Open:
      'FAQ - Can I refinance with zero plus as well Question Open',
    aboutKellerMortgageQuestion5Close:
      'FAQ - Can I refinance with zero plus as well Question Close',
    aboutLoansQuestion1Open:
      'FAQ - I want to learn about what kinds of loans Keller Mortgage offers Question Open',
    aboutLoansQuestion1Close:
      'FAQ - I want to learn about what kinds of loans Keller Mortgage offers Question Close',
    aboutLoansQuestion2Open:
      'FAQ - How does Keller Mortgage deliver such a strong pre-approval Question Open',
    aboutLoansQuestion2Close:
      'FAQ - How does Keller Mortgage deliver such a strong pre-approval Question Close',
    aboutLoansQuestion3Open:
      'FAQ - How does Keller Mortgage offer such a great deal Question Open',
    aboutLoansQuestion3Close:
      'FAQ - How does Keller Mortgage offer such a great deal Question Close',
    aboutLoansQuestion4Open:
      'FAQ - In which states is Keller Mortgage licensed to do business Question Open',
    aboutLoansQuestion4Close:
      'FAQ - In which states is Keller Mortgage licensed to do business Question Close',
    aboutLoansQuestion5Open:
      'FAQ - What is the minimum credit score Question Open',
    aboutLoansQuestion5Close:
      'FAQ - What is the minimum credit score Question Close',
    aboutLoansQuestion6Open:
      'FAQ - Where are your rate sheets located Question Open',
    aboutLoansQuestion6Close:
      'FAQ - Where are your rate sheets located Question Close',
    aboutLoansQuestion7Open:
      'FAQ - What is the difference between Pre-qualification and Pre-approval Question Open',
    aboutLoansQuestion7Close:
      'FAQ - What is the difference between Pre-qualification and Pre-approval Question Close',
    aboutLoansQuestion8Open:
      'FAQ - What is the minimum loan amount I can apply for Question Open',
    aboutLoansQuestion8Close:
      'FAQ - What is the minimum loan amount I can apply for Question Close',
    aboutLoansQuestion9Open:
      'FAQ - Where do I send my first payment Question Open',
    aboutLoansQuestion9Close:
      'FAQ - Where do I send my first payment Question Close',
    clickedViewMore: 'FAQ - Click View More Questions',
    clickedHide: 'FAQ - Click Hide Questions',
  },
  forAgents: {
    page: 'For Agents - Load',
    agentLogin: 'For Agents - Click Log in button',
    notAnAgent: 'For Agents - Click Not a KW agent button',
    letsTalk: "For Agents - Click let's talk link",
    clickedBuyerTab: 'For Agents - Click Buyer Tab',
    clickedRefiTab: 'For Agents - Click REFI Customers Tab',
    clickedSellersTab: 'For Agents - Click Sellers Tab',
    secretToSuccessQuestion1Open:
      'For Agents - How can my clients access zero plus Question Open',
    secretToSuccessQuestion1OClose:
      'For Agents - How can my clients access zero plus Question Close',
    secretToSuccessQuestion2Open:
      "For Agents - I'd love to build a relationship — will I get a dedicated loan officer Question Open",
    secretToSuccessQuestion2OClose:
      "For Agents - I'd love to build a relationship — will I get a dedicated loan officer Question Close",
    secretToSuccessQuestion3Open:
      'For Agents - Tell me all the secrets about zero plus Question Open',
    secretToSuccessQuestion3OClose:
      'For Agents - Tell me all the secrets about zero plus Question Close',
    secretToSuccessQuestion4Open:
      'For Agents - This sounds great. How can I access the zero plus Program Question Open',
    secretToSuccessQuestion4OClose:
      'For Agents - This sounds great. How can I access the zero plus Program Question Close',
  },
  forBorrowers: {
    page: 'For Borrowers - Load',
    onClickCard1: 'For Borrowers - Click Complete application Card',
    onClickCard2: 'For Borrowers - Click Shared documents Card',
    onClickCard3: 'For Borrowers - Click Sign disclosures Card',
    onClickCard4: 'For Borrowers - Click Make a payment Card',
    clickedSecurityStatement: 'For Borrowers - Click Security Statement Link',
    clickedFaq: 'For Borrowers - Click FAQ Link',
    clickedContactUs: 'For Borrowers - Click Contact Us Link',
  },
  legal: {
    page: 'Legal - Load',
  },
  lokcinvite: {
    page: 'LO KC Invite - Load',
    submitForm: 'LoKcInvite - Submit invite form',
  },
  mortgageRate: {
    closeMortgageRateApp:
      'Mortgage Rate App - Click close window (x in upper right)',
    getAgentInfoPage: 'Mortgage Rate App - Get Agent Info Page - Load',
    getAgentInfoOption1:
      'Mortgage Rate App - Get Agent Info Page - I am working with a Keller Williams agent',
    agentSearchModal: 'Mortgage Rate App - Agent Search Modal - Load',
    aagentSearchModalClose:
      'Mortgage Rate App - Click Agent Search Modal Close Button',
    agentSearchModalSearch:
      'Mortgage Rate App - Click Agent Search Modal Search Button',
    agentResultsModal: 'Mortgage Rate App - Agent Results Modal - Load',
    agentResultsModalClose:
      'Mortgage Rate App - Click Agent Results Modal Close Button',
    agentResultsSearchAgain:
      'Mortgage Rate App - Click Agent Results Modal Search Again Button',
    agentResultsCantFind: "Mortgage Rate App - Click Can't find your Agent?",
    agentResultsChoseAgent: 'Mortgage Rate App - Click This is My Agent',
    agentNotFoundModal: 'Mortgage Rate App - Agent Not Found Modal - Load',
    agentNotFoundModalClose:
      'Mortgage Rate App - Click Agent Not Found Modal Close Button',
    agentNotFoundModalSearchAgain: 'Mortgage Rate App - Click Search Again',
    agentNotFoundModalContinue:
      'Mortgage Rate App - Click Agent Not Found Modal Continue Button',
    getAgentInfoOption2:
      'Mortgage Rate App - Get Agent Info Page - I am not working with a Keller Williams agent',
    noKwAgentModal: 'Mortgage Rate App - No KW Agent Modal - Load',
    noKwAgentModalContinue:
      'Mortgage Rate App - Click No KW Agent Modal Continue Button',
    noKwAgentModalClose:
      'Mortgage Rate App - Click No KW Agent Modal Close Button',
    noKwAgentModalOption1:
      'Mortgage Rate App - No KW Agent Modal - Find a Keller Williams agent',
    noKwAgentModalOption2:
      'Mortgage Rate App - No KW Agent Modal - Find a Keller Williams office',
    noKwAgentModalOption3:
      "Mortgage Rate App - No KW Agent Modal - I'm buying a Keller Williams listing",
    noKwAgentModalOption4:
      'Mortgage Rate App - No KW Agent Modal - Continue without ZeroPlus discount',
    skipAgentSelectionModal:
      'Mortgage Rate App - Skip Agent Selection Modal - Load',
    skipAgentSelectionClose:
      'Mortgage Rate App - Click Skip Agent Selection Modal Close Button',
    skipAgentSelectionApplyForZeroPlus:
      'Mortgage Rate App - Click Skip Agent Selection Modal Apply for zero plus Button',
    buyOrRefinancePage: 'Mortgage Rate App - Buy Or Refinance Page - Load',
    buyOrRefinanceOption1:
      'Mortgage Rate App - Buy Or Refinance Page - I want to buy a home',
    buyOrRefinanceOption2:
      'Mortgage Rate App - Buy Or Refinance Page - I want to refinace',
    buyOrRefinanceContinue:
      'Mortgage Rate App - Click Buy Or Refinance Page Continue Button',
    buyOrRefinanceSaveProgress:
      'Mortgage Rate App - Click Buy Or Refinance Page Save Progress Button',
    homeTypePage: 'Mortgage Rate App - Home Type Page - Load',
    homeTypeContinue:
      'Mortgage Rate App - Click Home Type Page Continue Button',
    homeTypeSaveProgress:
      'Mortgage Rate App - Click Home Type Page Save Progress Button',
    homeTypeOption1: 'Mortgage Rate App - Home Type Page - Single family home',
    homeTypeOption2: 'Mortgage Rate App - Home Type Page - Multi-family',
    homeTypeOption3: 'Mortgage Rate App - Home Type Page - Condo',
    homeTypeOption4: 'Mortgage Rate App - Home Type Page - Townhome',
    homeTypeOption5: 'Mortgage Rate App - Home Type Page - Other',
    propertyUsePage: 'Mortgage Rate App - Property Use Page - Load',
    propertyUseContinue:
      'Mortgage Rate App - Click Property Use Page Continue Button',
    propertyUseSaveProgress:
      'Mortgage Rate App - Click Property Use Page Save Progress Button',
    propertyUseOption1:
      'Mortgage Rate App - Property Use Page - Primary residence',
    propertyUseOption2:
      'Mortgage Rate App - Property Use Page - Secondary residence',
    propertyUseOption3:
      'Mortgage Rate App - Property Use Page - Seasonal residence',
    propertyUseOption4:
      'Mortgage Rate App - Property Use Page - Investment property',
    propertyAddressPage: 'Mortgage Rate App - Property Address Page - Load',
    propertyAddressContinue:
      'Mortgage Rate App - Click Property Address Page Continue Button',
    propertyAddressSaveProgress:
      'Mortgage Rate App - Click Property Address Page Save Progress Button',
    propertyAddressOption1:
      'Mortgage Rate App - Property Address Page - Yes I have an address',
    propertyAddressOption2:
      'Mortgage Rate App - Property Address Page - I dont have an address',
    purchaseDatePage: 'Mortgage Rate App - Purchase Date Page - Load',
    purchaseDateContinue:
      'Mortgage Rate App - Click Purchase Date Page Continue Button',
    purchaseDateSaveProgress:
      'Mortgage Rate App - Click Purchase Date Page Save Progress Button',
    purchaseDateOption1: 'Mortgage Rate App - Purchase Date - 0-3 Months',
    purchaseDateOption2: 'Mortgage Rate App - Purchase Date - 3-6 Months',
    purchaseDateOption3: 'Mortgage Rate App - Purchase Date - 6+ Months',
    purchaseDateOption4: 'Mortgage Rate App - Purchase Date - Not Sure',
    askingPricePage: 'Mortgage Rate App - Asking Price Page - Load',
    askingPriceContinue:
      'Mortgage Rate App - Click Asking Price Page Continue Button',
    askingPriceSaveProgress:
      'Mortgage Rate App - Click Asking Price Page Save Progress Button',
    whatIsYourCurrentAddressPage:
      'Mortgage Rate App - What is your current address - Load',
    whatIsYourCurrentAddressSaveProgress:
      'Mortgage Rate App - What is your current address - Save Progress Button',
    whatIsYourCurrentAddressContinue:
      'Mortgage Rate App - What is your current address - Continue Button',
    doesThisLookRightPage:
      'Mortgage Rate App - Does This Look Right Page - Load',
    doesThisLookRightContinue:
      'Mortgage Rate App - Click Does This Look Right Page Continue Button',
    doesThisLookRightSaveProgress:
      'Mortgage Rate App - Click Does This Look Right Page Save Progress Button',
    userInfoPage: 'Mortgage Rate App - User Info Page - Load',
    userInfoContinue: 'Mortgage Rate App - Click User Info Continue Button',
    userInfoSaveProgress:
      'Mortgage Rate App - Click User Info Save Progress Button',
    userInfoModal: 'Mortgage Rate App - User Info Modal - Load',
    userInfoModalClose:
      'Mortgage Rate App - Click User Info Modal Close Button',
    userInfoModalProceed:
      'Mortgage Rate App - Click User Info Modal Proceed to application Button',
    disclaimerPage: 'Mortgage Rate App - Disclaimer Page - Load',
    disclaimerAcceptButton:
      'Mortgage Rate App - Disclaimer Page - Click I Accept button',
    quotesPage: 'Mortgage Rate App - Quotes Page - Load',
    quotesPageContinue: 'Mortgage Rate App - Click Quotes Continue Button',
    successfulPreApplicationModal:
      'Mortgage Rate App - Successful PreApplication Modal - Load',
    successfulPreApplicationModalClose:
      'Mortgage Rate App - Click Successful PreApplication Modal Close Button',
    flowCompletedModal: 'Mortgage Rate App - Flow Completed Modal - Load',
    flowCompletedModalClose:
      'Mortgage Rate App - Click Flow Completed Modal Close Button',
    saveConfirmationModal: 'Mortgage Rate App - Save Confirmation Modal - Load',
    saveConfirmationModalClose:
      'Mortgage Rate App - Click Save Confirmation Modal Close Button',
    emailModal: 'Mortgage Rate App - Email Modal - Load',
    emailModalClose: 'Mortgage Rate App - Click Email Modal Close Button',
    emailModalSubmit: 'Mortgage Rate App - Click Email Modal Submit Button',
    clickedAcceptTerms: 'Mortgage Rate App - Click I Accept',
    errorModal: 'Mortgage Rate App - Unexpected error',
    errorModalClose: 'Mortgage Rate App - Unexpected error - Close',
  },
  invalidPartner: {
    invalidPartnerPage: 'Mortgage Rate App - Invalid Partner Page - Load',
  },
  offerReady: {
    page: 'Offer Ready - Load',
    clickedBackButton: 'Offer Ready - Click back button - Header Section',
    clickedApplyNow: 'Offer Ready - Click apply now button - Hero Section',
  },
  shared: {
    clickedLogoHeader: 'Click on main logo',
    clickedFaq: 'Click on FAQ link',
    clickedForBorrowersOpen: 'Click on For Borrowers link Open',
    clickedForBorrowersClose: 'Click on For Borrowers link Close',
    clickedForAgents: 'Click on For Agents link',
    clickedKwLogin: 'Click on Agent Login link',
    clickedPhoneNumber: 'Click on 1-888-766-2678 button',
    clickedApplyNow: 'Header - Click Apply Now Button',
    clickedYourDashboard:
      'Header - Borrowers Dropdown - Click Your Dashboard Link',
    clickedOurLoan:
      'Header - Borrowers Dropdown - Click Our Loan Offerings Link',
    clickedOfferReady: 'Header - Borrowers Dropdown - Click Offer Ready Link',
    clickedBorrowersFaq: 'Header - Borrowers Dropdown - Click FAQ Link',
    clickedContactUs: 'Header - Borrowers Dropdown - Click Contact Us Link',
    clickedApplyForAHome:
      'Header - Borrowers Dropdown - Click Apply for a home loan Link',
    clickedApplyForRefinancing:
      'Header - Borrowers Dropdown - Click Apply for refinancing Link',
    clickedCareers: 'Click on Careers',
    clickedBlog: 'Click on Blog',
    clickedLegal: 'Click on Legal',
    clickedLender:
      'Click on https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/140234',
  },
};
export default gaActions;
