export default [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'campaign_id',
  'kwuid',
  'propstate',
  'is_buyer_agent',
  'is_seller_agent',
  'team_id',
  'loan_mgr_from_preapp',
  'loan_manager_id',
  'partnerid',
  'buyorrefinance',
  'aba',
];
