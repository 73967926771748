import Link from 'next/link';
import Modal from 'components/commons/Modal/Modal';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import React, { useEffect } from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';
import sendGaEvent from 'src/utils/gtag';
import styles from './error-modal.module.scss';

const ErrorModal = () => {
  const [, send] = useFormMachine();

  useEffect(() => {
    sendGaEvent('errorModal', 'mortgageRate');
  }, []);

  const handleClose = () => {
    sendGaEvent('errorModalClose', 'mortgageRate');
    send('RETURN_HOME');
  };

  return (
    <Modal isOpen className={styles.modalContainer}>
      <p className={styles.text}>Oops! Something went wrong</p>
      <p className={styles.subText}>
        We apologize for the inconvenience, please resubmit your information.
      </p>
      <Link href="/">
        <SecondaryButton handleOnClick={() => handleClose()}>
          Return to KM home
        </SecondaryButton>
      </Link>
    </Modal>
  );
};

export default ErrorModal;
