import { BuyOrRefinance, FormContext, UserInfo } from 'src/common/types';
import { ActionFunctionMap, assign, ConditionPredicate } from 'xstate';

export const getUserInfoNode = {
  id: 'getUserInfo',
  on: {
    BACK: [
      {
        target: '#steps.innerSteps.doesThisLookRight.hist',
        cond: 'isRefinance',
      },
      { target: '#steps.innerSteps.askingPrice.hist' },
    ],
    SET_USER_INFO: {
      actions: 'setUserInfo',
    },
    NEXT: [
      {
        target: '#steps.innerSteps.getUserInfo',
        cond: 'incompleteUserInfo',
      },
      {
        target: '#innerSteps.disclaimer.inactiveState',
        cond: 'skipOptimalAndLoAbaOff',
        actions: [{ type: 'changeModal', modal: 'loading' }],
      },
      {
        target: '#innerSteps.disclaimer.fetching.fetchOptimalBlue',
        cond: 'skipFetchLoAbaOff',
        actions: { type: 'changeModal', modal: 'loading' },
      },

      {
        target: '#innerSteps.disclaimer.fetching.fetchLo',
        cond: 'isAbaOff',
        actions: { type: 'changeModal', modal: 'loading' },
      },
      {
        target: '#innerSteps.disclaimer.inactiveState',
        cond: 'skipOptimalAndLo',
      },
      {
        target: '#innerSteps.disclaimer.fetching.fetchOptimalBlue',
        cond: 'skipFetchLo',
      },
      {
        target: '#innerSteps.disclaimer.fetching.fetchLo',
      },
    ],
  },
};

export const getUserInfoActions: ActionFunctionMap<FormContext, any> = {
  setUserInfo: assign({
    userInfo: (_ctx, event) => event.userInfo as UserInfo,
    address: (ctx, event) => ({
      ...ctx.address,
      state: event.userInfo.state,
    }),
  }),
};

export const getUserInfoGuards: Record<
  string,
  ConditionPredicate<FormContext, any>
> = {
  isRefinance: context =>
    context.answers.buyOrRefinance === BuyOrRefinance.REFINANCE,
  skipFetchLo: context =>
    (!context.agent.KWUID || context.agent.KWUID === 'invalid_kwuid') &&
    !context.team.teamID,
  skipOptimalAndLo: context =>
    (!context.agent.KWUID || context.agent.KWUID === 'invalid_kwuid') &&
    !context.team.teamID &&
    !context.address.zip.number,
  isAbaOff: context => context.flowInfo.aba === 'off',
  skipOptimalAndLoAbaOff: context =>
    (!context.agent.KWUID || context.agent.KWUID === 'invalid_kwuid') &&
    !context.team.teamID &&
    !context.address.zip.number &&
    context.flowInfo.aba === 'off',
  skipFetchLoAbaOff: context =>
    (!context.agent.KWUID || context.agent.KWUID === 'invalid_kwuid') &&
    !context.team.teamID &&
    context.flowInfo.aba === 'off',
  incompleteUserInfo: context => {
    return (
      !context.userInfo.firstName ||
      !context.userInfo.lastName ||
      !context.userInfo.email ||
      !context.userInfo.phone ||
      (!context.userInfo.state && !context.address.state)
    );
  },
};
