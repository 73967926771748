import { FormContext, GetAgentInfo, Origin } from 'src/common/types';

const formContext: FormContext = {
  flowInfo: {
    origin: Origin.ORGANIC,
    sessionID: '',
    authToken: '',
    campaignID: 46,
    urlHasCampaignId: false,
    loanFromPreApp: '',
    isBuyerAgent: false,
    isSellerAgent: false,
    flowCompleted: false,
    isLeadSubmitted: false,
    error: null,
    aba: 'on',
  },
  utm: {
    source: '',
    medium: '',
    campaign: '',
    term: '',
    content: '',
  },
  answers: {
    getAgentInfo: GetAgentInfo.SKIPPED_KW_AGENT,
    buyOrRefinance: '',
    homeType: '',
    propertyUse: '',
    propertyAddress: '',
    purchaseDate: '',
    askingPrice: '',
  },
  agent: {
    firstName: '',
    lastName: '',
    phone: '',
    KWUID: '',
    photo: '',
    email: '',
    loanManagerId: '',
    loanManagerFirstName: '',
    loanManagerLastName: '',
    loanManagerEmail: '',
  },
  marketCenter: {
    id: '',
    name: '',
  },
  team: {
    teamID: '',
    teamName: '',
    teamPhone: '',
    teamEmail: '',
  },
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  address: {
    address: '',
    unit: '',
    street: '',
    streetNumber: '',
    state: '',
    city: '',
    county: '',
    zip: { number: '', valid: false },
    country: '',
  },
  dwellingDetails: {
    noOfStories: '',
    totalBathCount: '',
    numberOfBedrooms: '',
    yearBuilt: '',
    squareFeet: '',
    replacementCost: '',
    fireHydrantDistanceAnswer: '',
    fireStationDistanceAnswer: '',
  },
  liens: [],
  rates: {
    kwRate: 0,
    salesPayment: 0,
    downPayment: 0,
    downPaymentPercent: 20,
    creditsCoreRange: 780,
    loanTerm: 15,
  },
  termsOfService: {
    isAccepted: false,
    dateAccepted: '',
  },
  kellerCovered: {
    lead: 'ORGANIC',
    sessionID: null,
    accessToken: null,
    refreshToken: null,
    sourceIdentifier: '2460811077',
    contactID: null,
    updatedAt: null,
    contextChanged: false,
    allCallsFinished: false,
    isDisclaimerWaitingForCalls: false,
    quotePageVersion: 'checklistquotes',
  },
  quotes: {
    elapsedTime: 0,
    isChecklist: false,
    ownsHomeInsurance: false,
    quoteRequestID: null,
    quoteSelected: null,
    quotesList: null,
    quotesLoading: false,
    quotesStatus: null,
    saveForLater: false,
  },
  activeModal: '',
  propAddressOrNot: '',
  validUserInfo: false,
  activePage: '',
  lastState: '',
  redirectUrl: '',
  saveProgress: '',
  isLoadingToSplash: false,
  jvInfo: {
    legalName: 'Keller Mortgage, LLC',
    PartnerOrganizationName: 'Your Mortgage Application',
    PartnerID: 102100,
    path: 'keller-mortgage',
    SupportEmail: 'Optout@kellermortgage.com',
    Disclaimer: `<div>
  <p>This is to give you notice that Greater Howard County Market Center ("Market Center") with which your agent is affiliated, has a business relationship with Keller Mortgage, LLC (“Keller Mortgage”), KW Insurance, Ltd. (“Keller Covered”), and Mindset Home Services, LLC (“Originator”). Certain officers, directors, and owners of Keller Williams Realty, Inc. (“KWRI”), the franchisor Market Center, have indirect ownership interests ranging from an estimated one to 70 percent in Keller Mortgage. Likewise, certain officers, directors, and owners of KWRI have direct and indirect ownership interests ranging from an estimated one to 83 percent in Keller Covered. With regard to Originator, Keller Mortgage has a direct 51% ownership interest in Originator which means that certain officers, directors, and owners of KWRI have direct and indirect ownership interests ranging from an estimated one to 38 percent in Originator via their ownership in Keller Mortgage. Certain owners of The Waldner Winters Team, an agent affiliated with the Market Center, have a direct 49% ownership interest in Originator and 35% ownership interest in Universal Title. Because of these relationships, this referral may provide KWRI, KWRI officers, directors, or owners, and The Waldner Winters Team, officers, directors, or owners a financial or other benefit.</p>
</div>
<div>
  <p>Set forth below is the estimated charge or range of charges for the settlement services listed. You are NOT required to use the listed providers as a condition for settlement of your loan on or purchase, sale, or refinance of the subject property.</p>
</div>
<div>
  <p>THERE ARE FREQUENTLY OTHER SETTLEMENT SERVICE PROVIDERS AVAILABLE WITH SIMILAR SERVICES. YOU ARE FREE TO SHOP AROUND TO DETERMINE THAT YOU ARE RECEIVING THE BEST SERVICES AND THE BEST RATE FOR THESE SERVICES.</p>
</div>

<style type="text/css">
  .table {
    display: table;
    width: 100%;
    border-collapse: collapse;
  }

  .table-row {
    display: table-row;
  }

  .table-cell,
  .table-head {
    display: table-cell;
    padding: 1em;
    border: #f0f0f0 1px solid;
  }

  .table-head {
    font-weight: bold;
  }
</style>

<div class="table">

  <div class="table-row">

    <div class="table-head" align="center">Service Provider</div>

    <div class="table-head">Services Provided</div>

    <div class="table-head">Charge or Range of Charges</div>

  </div>

  <div class="table-row">

    <div class="table-cell" align="center">Keller Mortgage</div>

    <div class="table-cell">Loan Origination</div>

    <div class="table-cell">0 to 2% of loan amount*</div>
  </div>
  <div class="table-row">

    <div class="table-cell" align="center">&nbsp;</div>

    <div class="table-cell">Application Fee</div>

    <div class="table-cell">0 to $500*</div>
  </div>
  <div class="table-row">

    <div class="table-cell" align="center">&nbsp;</div>

    <div class="table-cell">Underwriting Fee</div>

    <div class="table-cell">0 to $875*</div>
  </div>
  <div class="table-row">

    <div class="table-cell" align="center">&nbsp;</div>

    <div class="table-cell">Processing Fee</div>

    <div class="table-cell">0 to $625*</div>
  </div>
  <div class="table-row">

    <div class="table-cell" align="center">&nbsp;</div>

    <div class="table-cell">Discount Fee/Points</div>

    <div class="table-cell">0 to 3% of loan amount**</div>
  </div>
  <div class="table-row">

    <div class="table-cell" align="center">Keller Covered</div>

    <div class="table-cell">Online Homeowners Insurance Quote Marketplace</div>

    <div class="table-cell">Consumers view various insurance options for their respective premiums and coverage options. Keller Covered does not charge consumers for its services.</div>
  </div>
  <div class="table-row">

    <div class="table-cell" align="center">Universal Title</div>

    <div class="table-cell">Title Insurance Services</div>

    <div class="table-cell">0.01% to 0.05% of loan amount</div>
  </div>
  <div class="table-row">

    <div class="table-cell" align="center">KWRI/Agent</div>

    <div class="table-cell">Real Estate Broker Services</div>

    <div class="table-cell">0 to 7% of purchase price of real property</div>
  </div>
  <div class="table-row">

    <div class="table-cell" align="center">Mindset Mortgage</div>

    <div class="table-cell">Mortgage Originator</div>

    <div class="table-cell">1-3.5% of loan amount</div>
  </div>
</div>
</div>
</div>
<div><em>* No origination, application, underwriting, or processing fees are charged in transactions with a Keller Williams agent.<br />** Discount fee/points are affected by the interest rate on your loan and may be higher if your interest rate is below market. In addition, the lender may require that you pay for the third-party services of an attorney, credit reporting agency, or real estate appraiser chosen by the lender to represent the lender’s interest.</em></div>
<div>
  <p><strong>Acknowledgement</strong>
  <p>
</div>
<div>
  <p>I/we have read this disclosure form and understand that referring party is referring me/us to purchase the above-described settlement service(s) and may receive a financial or other benefit as the result of this referral.</p>
</div>
<div>
  <p><strong>TCPA Acknowledgement</strong></p>
</div>
<div>
  <p>I affirmatively consent to receive calls and text messages from Mindset Home Services, including which may be dialed using automated technology, at the phone number provided. I am free to cancel this authorization at any time.
    Email us at compliance@mindsetmortgage.com for further information.</p>
</div>`,
  },
  partnerId: 102100,
};

export default formContext;
