/* eslint-disable react/button-has-type */
import React from 'react';
import Link from 'next/link';
import styles from './secondary-button.module.scss';

interface SecondaryButtonProps {
  disabled?: boolean;
  children: string | React.ReactNode;
  color?: 'secondary' | 'white';
  handleOnClick?: (e: React.MouseEvent<HTMLElement>) => void;
  linkTo?: string;
  specialWidth?: string;
  type?: 'button' | 'submit' | 'reset';
  testID?: string;
  cypressTag?: string;
  className?: string;
  innerClassName?: string;
}

const SecondaryButton = ({
  disabled,
  children,
  color = 'secondary',
  handleOnClick,
  linkTo,
  specialWidth = '',
  type = 'button',
  testID,
  cypressTag,
  className = '',
  innerClassName = '',
}: SecondaryButtonProps) => {
  const dynamicClasses = `${styles[color]} ${styles[specialWidth]} ${
    disabled && styles.disabled
  } ${className}`;

  const specialLink = () => {
    if (linkTo && (linkTo.includes('mailto:') || linkTo.includes('tel:'))) {
      return true;
    }
    return false;
  };

  if (linkTo && !disabled && !specialLink()) {
    return (
      <Link href={linkTo}>
        <a
          href={linkTo}
          onClick={handleOnClick}
          className={`${styles.pillButton} ${dynamicClasses}`}
        >
          <button
            disabled={disabled}
            onClick={handleOnClick}
            type={type}
            data-testid={testID}
            data-cy={cypressTag}
          >
            <span className={innerClassName}>{children}</span>
          </button>
        </a>
      </Link>
    );
  }

  if (specialLink()) {
    return (
      <a
        className={`${styles.pillButton} ${dynamicClasses}`}
        href={linkTo}
        onClick={handleOnClick}
      >
        <button
          disabled={disabled}
          onClick={handleOnClick}
          type={type}
          data-testid={testID}
          data-cy={cypressTag}
        >
          <span className={innerClassName}>{children}</span>
        </button>
      </a>
    );
  }

  return (
    <button
      className={`${styles.pillButton} ${dynamicClasses}`}
      disabled={disabled}
      onClick={handleOnClick}
      type={type}
      data-testid={testID}
      data-cy={cypressTag}
    >
      <span className={innerClassName}>{children}</span>
    </button>
  );
};

export default SecondaryButton;
