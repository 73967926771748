import React, { useEffect, useState } from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import { inspect } from '@xstate/inspect';
import ModalsHandler from 'components/commons/ModalsHandler/ModalsHandler';
import RouteHandler from 'components/commons/RouteHandler/RouteHandler';
import SEO from 'components/commons/Seo/SEO';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Modal from 'react-modal';
import { MainFormProvider } from 'src/context/FormMachineProvider';
import { pageview } from 'src/utils/gtag';
import { freshChat, gaTagScript } from 'src/utils/scripts';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '../src/styles/async-select.scss';
import '../src/styles/globals.scss';
import '../src/styles/modal.scss';
import '../src/styles/react-select.scss';
import '../src/styles/transitions.scss';

const inspectEnabled = process.env.NODE_ENV === 'development';
config.autoAddCss = false;

if (typeof window !== 'undefined' && inspectEnabled) {
  inspect({
    iframe: false,
  });
}

Modal.setAppElement('#__next');

function App({ Component, pageProps, err }: AppProps & { err: any }) {
  const router = useRouter();
  const isJv = process.env.NEXT_PUBLIC_DEPLOY_VARIATION === 'mypreapp';
  const [chatbotLoaded, setChatbotLoaded] = useState(false);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <MainFormProvider>
        <SEO />
        <RouteHandler isNotFound={Component.name === 'NotFound'}>
          <Component {...pageProps} err={err} />
          <ModalsHandler />
        </RouteHandler>
      </MainFormProvider>
      {!isJv && (
        <>
          {/* FreshChat (Chatbot) */}
          <Script
            strategy="lazyOnload"
            src="https://wchat.freshchat.com/js/widget.js"
            onLoad={() => setChatbotLoaded(true)}
          />
          {chatbotLoaded && (
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{ __html: freshChat }}
            />
          )}
          {/* End FreshChat */}
        </>
      )}
      ,{/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{ __html: gaTagScript }}
      />
      {/* End Google Analytic */}
      {/* Google Maps */}
      <Script
        strategy="beforeInteractive"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}&libraries=places`}
      />
      {/* End Google Maps */}
      {/* Glassbox Detector */}
      <Script
        strategy="beforeInteractive"
        id="_cls_detector"
        data-clsconfig={`reportURI=${process.env.NEXT_PUBLIC_GLASSBOX_REPORT_URI}`}
        src={process.env.NEXT_PUBLIC_GLASSBOX_DETECTOR_LOCATION}
      />
      {/* End Glassbox Detector */}
    </>
  );
}

export default App;
