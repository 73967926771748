import {
  AgentSearchContext,
  AgentSearchSchema,
  AgentSearchTransitions,
} from 'src/common/types';
import {
  Machine,
  sendParent,
  assign,
  MachineConfig,
  MachineOptions,
} from 'xstate';
import { searchAgent } from '../utils/fetchActions';
import formContext from './context';

const searchAgentMachineConfiguration: MachineConfig<
  AgentSearchContext,
  AgentSearchSchema,
  AgentSearchTransitions
> = {
  id: 'searchAgent',
  initial: 'idle',
  context: {
    selectedAgent: null,
    agents: null,
    searchTerm: null,
    error: null,
    marketCenter: null,
    city: null,
    state: null,
  },
  states: {
    idle: {
      entry: sendParent({
        type: 'ACTIVATE_MODAL',
        modal: 'agentSearch',
      }),
      on: {
        SEARCH: {
          target: 'loading',
          actions: 'saveSearchTerm',
        },
        SEARCH_BY_CITY: {
          target: 'loading',
          actions: 'saveCity',
        },
        SELECT_AGENT: {
          target: 'agentSelected',
          actions: [
            'selectAgent',
            sendParent({
              type: 'ACTIVATE_MODAL',
              modal: '',
            }),
          ],
        },
      },
    },
    loading: {
      entry: sendParent({
        type: 'ACTIVATE_MODAL',
        modal: 'agentResults',
      }),
      invoke: {
        id: 'getAgents',
        src: context => searchAgent(context.searchTerm!),
        onDone: {
          target: 'success',
          actions: assign((_context, event) => ({
            agents: event.data.agents,
            cities: event.data.cities,
          })),
        },
        onError: {
          target: 'failure',
          actions: assign({ error: (_context, event) => event.data }),
        },
      },
    },
    failure: {
      on: {
        SEARCH_AGAIN: 'idle',
        AGENT_NOT_FOUND: {
          target: 'agentNotFound',
          actions: 'selectAgent',
        },
      },
    },
    success: {
      on: {
        SEARCH_AGAIN: 'idle',
        SEARCH: {
          target: 'loading',
          actions: 'saveSearchTerm',
        },
        SEARCH_BY_CITY: {
          target: 'loading',
          actions: 'saveCity',
        },
        SELECT_AGENT: {
          target: 'agentSelected',
          actions: [
            'selectAgent',
            sendParent({
              type: 'ACTIVATE_MODAL',
              modal: '',
            }),
          ],
        },
        AGENT_NOT_FOUND: {
          target: 'agentNotFound',
          actions: 'selectAgent',
        },
      },
    },
    agentNotFound: {
      entry: sendParent({
        type: 'ACTIVATE_MODAL',
        modal: 'agentNotFound',
      }),
      on: {
        SEARCH_AGAIN_AGENT_NOT_FOUND: {
          target: 'idle',
        },
        CONTINUE_AGENT_NOT_FOUND: {
          target: 'agentSelected',
          actions: sendParent({
            type: 'ACTIVATE_MODAL',
            modal: '',
          }),
        },
      },
    },
    agentSelected: {
      type: 'final',
      data: {
        selectedAgent: (context: AgentSearchContext, event: any) =>
          event.type === 'CONTINUE_AGENT_NOT_FOUND'
            ? formContext.agent
            : context.selectedAgent,
        marketCenter: (context: AgentSearchContext, event: any) =>
          event.type === 'CONTINUE_AGENT_NOT_FOUND'
            ? formContext.marketCenter
            : context.marketCenter,
      },
    },
  },
};

const searchAgentMachineOptions: Partial<
  MachineOptions<AgentSearchContext, any>
> = {
  actions: {
    selectAgent: assign({
      selectedAgent: (_context, event) => event.selectedAgent,
      marketCenter: (_context, event) => event.marketCenter,
    }),
    saveSearchTerm: assign({
      searchTerm: (_context, event) => event.searchTerm,
    }),
    saveCity: assign({
      city: (_context, event) => <string>event.city,
      state: (_context, event) => <string>event.state,
      searchTerm: _context => '',
    }),
  },
};

const searchAgentMachine = Machine(
  searchAgentMachineConfiguration,
  searchAgentMachineOptions
);

export default searchAgentMachine;
