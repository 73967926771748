import { ActionMeta } from 'xstate';
import {
  AgentResponse,
  LoanOfficerResponse,
  SessionResponse,
} from 'src/common/apiSchemas';
import {
  Agent,
  Team,
  Address,
  FormContext,
  StateToSave,
  ContactUsFormData,
  SearchKWAgentData,
  FormEvent,
} from 'src/common/types';
import { getState } from 'src/utils';
import {
  sessionPayload,
  preApprovalPayload,
  contactPayload,
  quotePayload,
  hubspotQuotePayload,
} from 'src/utils/payload';
import optiLang from 'src/lang/mortgageApplication/paymentCalculator.lang';
import { client } from './api-client';
import { formatAddress } from './formats';
import { HandleError } from './sentry';

export const getTotalSavings = async () => {
  const data = await client(process.env.NEXT_PUBLIC_TOTAL_SAVINGS_API!);
  return data[0];
};

export const searchAgent = async (agentName: string) => {
  const response: AgentResponse = await client(
    `${process.env.NEXT_PUBLIC_SEARCH_AGENT_API!}/?take=15&search=${agentName}`
  );
  return response;
};

export const searchAgentById = async (id: string, authToken: string) => {
  if (id === '000000') {
    return 'invalid_kwuid';
  }
  const response: AgentResponse = await client(
    `${process.env.NEXT_PUBLIC_SEARCH_AGENT_API!}/${id}`,
    {
      token: authToken,
    }
  );
  return response;
};

export const searchByTeamId = async (id: string): Promise<Team> => {
  const searchByTeamIdResponse = await client(
    `${process.env.NEXT_PUBLIC_SEARCH_TEAM_API!}${id}`
  );
  const teamResult =
    searchByTeamIdResponse?.result?.[0]?.TeamID &&
    searchByTeamIdResponse?.result?.[0];

  return {
    teamID: teamResult?.TeamID || '',
    teamName: teamResult?.TeamName || '',
    teamPhone: teamResult?.KMTeamPhone || '',
    teamEmail: teamResult?.KMTeamEmail || '',
  };
};

const searchLOAgentById = async (
  id: string,
  state: string,
  teamId?: string,
  partnerId?: number
) => {
  const isJv = process.env.NEXT_PUBLIC_DEPLOY_VARIATION === 'mypreapp';
  const queryTeamId = teamId ? `&TeamID=${teamId}` : '';
  const queryPartnerId = partnerId !== 102100 ? `&partnerid=${partnerId}` : '';

  const url = isJv
    ? process.env.NEXT_PUBLIC_SEARCH_PREFERRED_LOAN_OFFICER_API_MYPREAPP!
    : process.env.NEXT_PUBLIC_SEARCH_PREFERRED_LOAN_OFFICER_API!;

  const req = `${url}?KWUID=${id}&StateCode=${state}${queryTeamId}${queryPartnerId}`;

  const searchLOByIdResponse: LoanOfficerResponse = await client(req);
  const LO = {
    id: searchLOByIdResponse?.result?.[0]?.ID || null,
    firstName: searchLOByIdResponse?.result?.[0]?.FirstName || null,
    lastName: searchLOByIdResponse?.result?.[0]?.LastName || null,
    email: searchLOByIdResponse?.result?.[0]?.Email || null,
  };
  return LO;
};

export const fetchLo = async (
  agent: Agent,
  team: Team,
  address: Address,
  partnerId: number
): Promise<{
  id: number | string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}> => {
  const { KWUID } = agent;
  const loID = agent.loanManagerId;
  const { teamID } = team;
  const state = getState(address);
  let LO: {
    id: number | string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  };
  if (loID === '' && KWUID !== 'invalid_kwuid' && KWUID !== '') {
    LO = await searchLOAgentById(KWUID, state, undefined, partnerId);
  } else if (KWUID === 'invalid_kwuid' && teamID !== '') {
    LO = await searchLOAgentById('000000', state, teamID, partnerId);
  } else {
    LO = { id: loID, lastName: '', firstName: '', email: '' };
  }

  if (LO) {
    return LO;
  }
  throw new Error('Error fetching LO');
};

export const getSessionId = async (sessionData: any) => {
  const response: SessionResponse = await client(
    `${process.env.NEXT_PUBLIC_KM_SESSION_DATA!}`,
    {
      data: sessionData,
    }
  );
  return { sessionID: response.mortgageSession.id, authToken: response.token };
};

export const updateSessionData = async (
  sessionData: any,
  sessionID: string,
  authToken: string
) => {
  const response: SessionResponse = await client(
    `${process.env.NEXT_PUBLIC_KM_SESSION_DATA!}/${sessionID}`,
    {
      method: 'PUT',
      data: sessionData,
      token: authToken,
    }
  );
  return response.mortgageSession.id;
};

export const getOrUpdateSessionId = async (
  context: FormContext,
  actionMeta: ActionMeta<FormContext, FormEvent> | null
) => {
  const step = JSON.stringify(actionMeta?.state.value || '').match(
    /getAgentInfo|buyOrRefinance|homeType|propertyUse|propertyAddress|purchaseDate|askingPrice|currentAddress|getUserInfo|whatIsYourCurrentAddress|doesThisLookRight|disclaimer|quotes|submitData/
  ) || [''];

  const stateToSave: StateToSave | null = actionMeta
    ? {
        // eslint-disable-next-line no-underscore-dangle
        _event: actionMeta.state._event,
        context: {
          ...actionMeta.state.context,
          jvInfo: { legalName: '' },
          activeModal: '',
          liens: [],
        },
        children: {},
        value: actionMeta.state.value,
      }
    : null;

  const {
    flowInfo: { sessionID: currentSessionID, authToken: currentAuthToken },
  } = context;
  const sessionData = sessionPayload(context, step[0], stateToSave);
  const sessionID = currentSessionID
    ? await updateSessionData(sessionData, currentSessionID, currentAuthToken)
    : await getSessionId(sessionData);

  if (!sessionID) throw new Error('Error getting session ID');
  return sessionID;
};

export const postReferral = async (id: string, authToken: string) => {
  const response = await client(process.env.NEXT_PUBLIC_KM_REFERRALS_API!, {
    token: authToken,
    data: { session_id: id },
  });
  if (response) {
    return true;
  }
  return null;
};

export const postPreApproval = async (context: FormContext) => {
  const response = await client(process.env.NEXT_PUBLIC_LO_DASHBOARD_API!, {
    data: preApprovalPayload(context),
  }).catch(err =>
    // to send a custom message on this log if api call fails
    HandleError('message', 'No redirect url returned to user', err)
  );

  if (!response.data?.attributes?.redirect_url) {
    // to send log if the api call is successfull but it doesn't return a redirect_url
    HandleError('message', 'No redirect url returned to user', response);
    return Promise.reject(response);
  }
  return response;
};

export const getLoanData = async (
  askingPrice: string,
  zipcode: string,
  sessionID: string
) => {
  // This value may be choosed by the user in the future
  const periodInYears = optiLang.mortgagePeriodOptions[0].value;
  const body = {
    askingPrice: askingPrice.toString(),
    loanTerms: periodInYears,
    zipcode,
    mortgageSessionId: sessionID,
  };
  const response = await client(process.env.NEXT_PUBLIC_OPTIMAL_BLUE_API!, {
    headers: {
      'x-api-key': process.env.NEXT_PUBLIC_OPTIMAL_BLUE_API_TOKEN!,
      'Content-Type': 'application/json',
    },
    data: body,
  });
  // right now only one 15 year product is ever returned
  const nationalLoanName =
    periodInYears === 'ThirtyYear'
      ? optiLang.nationalLoanName
      : 'Conforming15YrFixed';
  const loanProducts = {
    kellerWilliams: response.kellerWilliams,
    national: response.national.filter(
      (obj: any) => obj.indexName === nationalLoanName
    ),
  };
  return {
    salesPayment: Math.ceil(+askingPrice * 0.8),
    downPayment: Math.ceil(+askingPrice * 0.2),
    kwRate: loanProducts.kellerWilliams[0].rate,
    loanTerm: periodInYears === 'ThirtyYear' ? 30 : 15,
  };
};

export const saveProgress = async (email: string, link: string) => {
  // https://docs.aws.amazon.com/ses/latest/DeveloperGuide/send-email-simulator.html - Find test emails for develop
  const response = await client(process.env.NEXT_PUBLIC_KM_EMAIL_API!, {
    headers: {
      'X-API-Key': process.env.NEXT_PUBLIC_KM_EMAIL_API_KEY!,
      'Content-Type': 'aplication/json',
    },
    data: { email, link },
  });
  if (response) {
    return true;
  }
  return false;
};

export const sendContactForm = async (values: ContactUsFormData) => {
  const { fullname, message, email, state } = values;
  const body = {
    fullName: fullname,
    email,
    message,
    state,
  };

  const response = await client(process.env.NEXT_PUBLIC_KM_CONTACT_US_API!, {
    headers: {
      'Content-Length': JSON.stringify(body).length.toString(),
    },
    data: body,
  }).catch(() => false);

  return response;
};

export const dropdownSearchKWAgent = async (
  searchKey: string,
  signal?: AbortSignal
): Promise<SearchKWAgentData | []> => {
  if (!searchKey) {
    return [];
  }
  const response = await client(
    `${process.env.NEXT_PUBLIC_SEARCH_AGENT_API}/?search=${searchKey}`,
    { signal }
  ).catch(() => 'error');
  // user cancel the fetch but is still typing
  if (response === 'error' && searchKey.length >= 3) {
    return { data: [], loading: true };
  }
  if (response === 'error') {
    return { data: [], loading: false };
  }
  return {
    data: [
      ...response.agents?.slice(0, 3),
      ...response.cities,
      ...response.agents?.slice(3),
    ],
    loading: false,
  };
};

export const getAddress = async (address: string) => {
  const response = await client(
    `${process.env.NEXT_PUBLIC_GOOGLE_GEOCODE}${address}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}`
  );
  return response;
};

export const getLocation = async (zip?: number, address?: string) => {
  const query = zip || address;
  const data = await client(
    `${process.env.NEXT_PUBLIC_GOOGLE_GEOCODE}${query}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}`
  );
  return data;
};

export const getHomeData = async (
  formattedAddress: string,
  authToken: string
) => {
  const data = await client(
    `${process.env.NEXT_PUBLIC_KC_DWELLING_DETAILS}${formattedAddress}`,
    { headers: { Accept: 'application/json' }, token: authToken }
  );
  return data;
};
// Splash page related

export const getKCSession = async (lead: string, sourceIdentifier: string) => {
  const response = await client(process.env.NEXT_PUBLIC_KC_SESSION!, {
    data: { lead, source_identifier: sourceIdentifier },
  });

  return response;
};

export const getDwellingDetails = async (context: FormContext) => {
  const {
    kellerCovered: { sessionID, accessToken },
    address: { address },
  } = context;
  const formattedAddress = formatAddress(address);
  const response = await client(
    `${process.env.NEXT_PUBLIC_KC_CREATE_DWELLING_DETAILS}?session_id=${sessionID}`,
    {
      token: accessToken,
      data: { address: formattedAddress },
    }
  );
  return response;
};

export const saveContactInformation = async (context: FormContext) => {
  const {
    kellerCovered: { sessionID, accessToken },
  } = context;
  const requestData = contactPayload(context);
  const response = await client(
    `${process.env.NEXT_PUBLIC_KC_SESSION}/${sessionID}/contact`,
    {
      token: accessToken,
      data: requestData,
    }
  );
  return response;
};

// first request to the backend to start getting quotes
export const getQuotes = async (context: FormContext) => {
  const {
    kellerCovered: { sessionID, accessToken },
  } = context;
  const requestData = quotePayload(context);
  const response = await client(
    `${process.env.NEXT_PUBLIC_KC_GET_QUOTES}?session_id=${sessionID}`,
    {
      token: accessToken,
      data: requestData,
    }
  );
  return response;
};

// request to get the quotes we asked for on the first request
export const fetchQuotes = async (context: FormContext) => {
  const {
    kellerCovered: { sessionID, accessToken },
    quotes: { quoteRequestID },
  } = context;
  const response = await client(
    `${process.env.NEXT_PUBLIC_KC_QUOTE_REQUEST}/${quoteRequestID}?&session_id=${sessionID}`,
    {
      token: accessToken,
    }
  );
  return response;
};

export const sendQuoteToHubspot = async (context: FormContext) => {
  const sendQuoteToHubspotPayload = hubspotQuotePayload(context);
  const response = await client(
    process.env.NEXT_PUBLIC_KC_TO_HUBSPOT_REQUEST!,
    {
      data: sendQuoteToHubspotPayload,
    }
  );

  return response;
};

// invite lo
export const postInviteLoKc = async (data: any) => {
  const response = await client(process.env.NEXT_PUBLIC_INVITE_LO_KC_API!, {
    data,
  }).catch(() => false);
  if (response) {
    return true;
  }
  return false;
};

export const searchKWAgent = async (searchKey: string) => {
  const response = await client(
    `${process.env.NEXT_PUBLIC_SEARCH_AGENT_API}/?search=${searchKey}`
  );
  return response?.agents;
};

export const searchLOOfficerByName = async (name: string) => {
  const response = await client(
    `${process.env.NEXT_PUBLIC_SEARCH_LOAN_OFFICER_BY_NAME}?search=${name}`,
    {
      headers: {
        Authorization: process.env.NEXT_PUBLIC_SEARCH_LOAN_OFFICER_BY_NAME_AUTH,
      },
    }
  );

  return response;
};

export const getJvPartnerDetails = async (partnerId: number) => {
  const response = await client(
    `${process.env.NEXT_PUBLIC_GET_JV_PARTNER_DETAILS}?PartnerID=${partnerId}`,
    {
      headers: {
        'api-token': process.env.NEXT_PUBLIC_GET_JV_PARTNER_DETAILS_TOKEN,
      },
    }
  ).catch(error =>
    HandleError('message', 'Error when retreiving JV info', error)
  );
  return response;
};
